import axios from "axios";
import { baseUrl } from "../template/config";

// export const EmployeeQR = await axios.get(urlEmp);

export const EmployeeQR = async (empcode, checkType) => {
  // send get api by axios
  const url = `${baseUrl}/${checkType}/${empcode}`;
  return axios.get(url);
};
