import { Button, Grid } from "@material-ui/core";
import React from "react";
import QRCode from "react-qr-code";
import placeholderQR from "../assets/images/placeholderQR.png";

const QrComponent = ({
  name,
  qrCode,
  stateQr = false,
  istemplate = true,
  handleDownload,
}) => {
  if (name && qrCode && stateQr) {
    return (
      <Grid>
        <Grid className="box-QR">
          <QRCode value={qrCode} />
        </Grid>
        <Grid className="text-booth">{name}</Grid>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => handleDownload(name, qrCode, istemplate)}
        >
          DOWNLOAD
        </Button>
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Grid className="box-QR">
          <img src={placeholderQR} alt="Logo" />
        </Grid>
        <Grid className="text-pls-gen">
          {"Please select survey options and click “GENERATE QR CODE”"}
        </Grid>
      </Grid>
    );
  }
};

export default QrComponent;
