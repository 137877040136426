// @ts-nocheck
import "./assets/css/app.css";
import {
  Grid,
} from "@material-ui/core";
import React from "react";
import MainComponent from "./components/mainComponent";

function App() {

  return (
    <>
      <Grid className="containerTitle">
        <h1 className="title">QR Generator</h1>
        <p className="description">Select the options below to create a QR Code for the branch survey</p>
      </Grid>
      <MainComponent />
    </>
  );
}

export default App;
